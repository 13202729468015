@import "../css//variables.scss";
@import "../css/mixin.scss";

body {
  background-color: #f0f9fc;
}

.main-section-AddVehicle {
  @include main-body;
  margin-left: 90px;


}

.main-section-AddVehicle {
  h5 {
    color: #33778E;
  }

  label {
    color: #66739B;
  }

  hr {
    height: 1px;
    color: #4f9fe6;
  }

  .main-body-AddVehicle {

    .drag-drop {
      color: #B2BAD3;
      font-size: 14px;
    }

  }

  #heading-addVehicles {
    text-align: left;
    font: normal normal bold 18px Overpass;
    letter-spacing: 0.86px;
    color: #33778E !important;
    text-transform: uppercase;
    opacity: 1;
  }

  //   height: 100vh;
  .progress-block {
    height: auto;
    background-color: #d4edf5;
    border-radius: 15px;



    .progress-status {
      text-align: right;
      font: normal normal 600 16px/25px Overpass;
      letter-spacing: 0.38px;
      color: #2C3244;
      opacity: 1;
    }

    .progress {
      padding: 2px;
      background-color: #f8faff;

      .progress-bar {
        background-color: #98c4eb;
      }
    }
  }

  .card-heading {
    text-align: left;
    font: normal normal 600 16px/25px Overpass;
    letter-spacing: 0.38px;
    color: #33778E;
    opacity: 1;
  }

  .main-vehicle-info {

    @include common-body;

    .border-block {
      border: 1px dashed #DDDDDD;
      border-radius: 10px;
      opacity: 1;

      .uploading {
        padding: 70px;
        text-align: center;
      }
    }

    .item_list {
      position: relative;
      margin-right: 15px;
    }

    .close_item {
      position: absolute;
      left: 40px;
      z-index: 220;
      top: -3px;

    }
  }

}

.fix-bottom-bar {

  height: 60px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px -4px 16px #0076EC1A;
opacity: 1;

  .cancel-btn {
    text-decoration: none;
    color: #4F9FE6;
    background-color: #ECF5FD;
    border-radius: 5px;
    padding: 7px 15px;
    margin-right: 10px;
    border: none;
  }

  .continue-btn {
    text-decoration: none;
    color: #FFFFFF;
    border: none;
    background-color: #4F9FE6;
    border-radius: 5px;
    padding: 7px 15px;
    margin-right: 20px;
  }
}

.owner-details {
  margin-bottom: 80px;
}

.form-check-input {
  background: #4fbfa434 0% 0% no-repeat padding-box;
  border-radius: 5px !important;
  opacity: 1;
  height: 16px;
  width: 16px;
}

.form-check-input:checked {
  background-color: #4fbfa4 !important;
}

.form-check-input:checked[type="checkbox"] {
  //    background-image: url(../images/tick_green.svg) !important;

  // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e);
}